import { api, methods, toFormData } from "../api";

export const authApi = api.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation<LoginResponse, loginData>({
            query: body => ({
                url: 'login',
                method: methods.post,
                body: toFormData(body)
            })
        }),
        logout: builder.query({
            query: () => ({
                url: 'logout',
            })
        }),
        restore: builder.mutation<BasicResponse, restoreData>({
            query: body => ({
                url: 'forget-password',
                method: methods.post,
                body: toFormData(body)
            })
        }),
        activation: builder.query<BasicResponse, emailActionvation>({
            query: (params) => ({
                url: `activate-account/${params.key}`,
            })
        }),
        emailValidation: builder.query<BasicResponse, emailActionvation>({
            query: (params) => ({
                url: `confirm-change-email/${params.key}`,
            })
        }),
        passwordRequest: builder.query<BasicResponse, emailActionvation>({
            query: (params) => ({
                url: `new-password/${params.key}`,
            })
        }),
        setNewPassword: builder.mutation<BasicResponse, newPasswordData>({
            query: body => ({
                url: `new-password/${body.key}`,
                method: methods.post,
                body: toFormData(body)
            })
        }),
        register: builder.mutation<BasicResponse, registrationData>({
            query: body => ({
                url: 'registrate',
                method: methods.post,
                body: toFormData(body)
            })
        }),
        pinCodeAuth: builder.mutation<BasicResponse, PinData>({
            query: body => ({
                url: 'add-device-by-code',
                method: methods.post,
                body: toFormData(body)
            })
        }),
    })
})

export const { 
    useLoginMutation, 
    useLazyLogoutQuery, 
    useRestoreMutation, 
    useRegisterMutation,
    useSetNewPasswordMutation,
    useLazyActivationQuery,
    useLazyEmailValidationQuery,
    useLazyPasswordRequestQuery,
    usePinCodeAuthMutation
} = authApi;