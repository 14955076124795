import { useNavigate } from "react-router-dom";
import { Logo } from "./ui/Logo";

export const NotFound = () => {
    const navigate = useNavigate();

    return (
        <div className="notFound">
            <Logo onClick={() => navigate('/')}/>
            <p className="err">404</p>
            <p className="errText">Not found. Click on the logo to go back and try again.</p>
        </div>  
    )
}
