
type logoProps = {
    onClick?: () => void
}

/**
 * App logo component with variable width/height/fill color
 */
export const Logo = (props: logoProps) => {
    const { 
        onClick
    } = props;

    return (
        <div 
            className="logo"
            onClick={onClick}
        />
    )
}
