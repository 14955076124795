import "./styles/global.scss";
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/600-italic.css"
import "@fontsource/montserrat/700.css"
import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import { Throbber } from "./components/ui/informers/Throbber";

function App() {
    return (
        <Suspense fallback={<Throbber fullScreen/>}>
            <Outlet/>
        </Suspense>
    );
}

export default App;
