import { api, methods, tags, toFormData } from "../api";

const url = 'profile-worker';

export const userData = api.injectEndpoints({
    endpoints: builder => ({
        getUserData: builder.query<UserData, undefined>({
            query: () => ({ 
                method: methods.post,
                url,
                body: toFormData({
                    type: 'get-data'
                }),
            }),
            providesTags: [ tags.userData ],
            keepUnusedDataFor: 0
        }),
        changeMail: builder.mutation<UserData, newEmailData>({
            query: (body) => ({
                method: methods.post,
                url,
                body: toFormData({
                    ...body,
                    type: 'update-email',
                }),
            }),
            invalidatesTags: [ tags.userData ]
        }),
        changePassword: builder.mutation<UserData, newPasswordData>({
            query: (body) => ({
                method: methods.post,
                url,
                body: toFormData({
                    ...body,
                    type: 'update-password',
                }),
            }),
            invalidatesTags: [ tags.userData ]
        }),
    }),
})

export const { 
    useGetUserDataQuery, 
    useLazyGetUserDataQuery,
    useChangeMailMutation,
    useChangePasswordMutation
} = userData;