import { createSlice } from "@reduxjs/toolkit";
import { AppRootState } from "../store";

export const searchInitialState: searchStateType = {
    search: undefined,
    startdate: undefined,
    stopdate: undefined,
    category_id: undefined
};

const searchState = createSlice({
    name: 'searchState',
    initialState: searchInitialState,
    reducers: {
        setSearchState: (state, { payload }) => {
            state.search = payload.search;
            state.startdate = payload.startdate ? payload.startdate : undefined ;
            state.stopdate = payload.startdate && payload.stopdate ? payload.stopdate : undefined;
            state.category_id = payload.category_id !== 0 ? payload.category_id : undefined;
        }
    }
});

export const { setSearchState } = searchState.actions;

export const selectSearchState = (state: AppRootState) => state.searchState;

export default searchState.reducer;