import { NotFound } from "../../NotFound";

type thorbberProps = {
    fullScreen?: boolean,
    is404?: boolean
}

export const Throbber = ({ fullScreen = false, is404 }: thorbberProps) => {
    return (
        <div className={`loaderWrapper${fullScreen ? ' loaderFullScreen' : ''}`}>
            {
                !is404
                    ? <div className="loader"/>
                    : <NotFound/>
            }
        </div>
    )
}
