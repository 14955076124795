import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { api } from "./api";
import searchState from "./slices/searchState";
import userState from "./slices/userSlice";
import listenerMiddleware from "./listenerMiddleware";

const rootReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    searchState,
    userState
});

export const store = configureStore({
    reducer: rootReducer,  
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(listenerMiddleware)
    .concat([
        api.middleware, 
    ])
});

export type AppRootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;