import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useUserAuth } from "./hooks/useUserAuth";
import { useEffect, useMemo } from "react";
import { getRoutesByAuth } from "./routes";
import { useGetUserDataQuery } from "./app/endpoints/userData";
import { Throbber } from "./components/ui/informers/Throbber";
import useWindowDimensions from "./hooks/useWIndowDimensions";

export const AppRouter = () => {
    const { isLoading } = useGetUserDataQuery(undefined);
    const user = useUserAuth();
    const routes = useMemo(() => getRoutesByAuth(user.isLoggedIn), [user.isLoggedIn]);
    const router = createBrowserRouter(routes);

    const dimensions = useWindowDimensions();
    
    useEffect(() => {
        const root = document.getElementById('root') as HTMLElement;

        root.style.height = dimensions.height + 'px';
    }, [ dimensions ])

    return (
        isLoading
            ?   <Throbber fullScreen/>
            :   <RouterProvider router={router}/>
    )       
}
