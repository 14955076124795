import { ActionReducerMapBuilder, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppRootState } from "../store";
import { userData } from "../endpoints/userData";
import { authApi } from "../endpoints/authApi";

export const userInitialState: userStateType = {
    isLoggedIn: false,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    phone: undefined,
    unreadNotifications: false,
    warning: false
};

const resetUser = (state: userStateType) => {
    const rKey = state.recapcha_open_key;
    Object.assign(state, userInitialState);
    state.recapcha_open_key = rKey;
};

const setUser = (state: userStateType, { payload }: PayloadAction<UserData>) => {
    if (payload && !payload?.not_authorized) {
        state.email = payload?.email;
        state.phone = payload?.phone;
        state.firstName = payload?.first_name;
        state.lastName = payload?.last_name;
        state.warning = !!parseInt(payload?.is_show_warning || "0");
        state.unreadNotifications = !!parseInt(payload?.is_isset_unread_notifications || "0");
        state.isLoggedIn = true;
        state.recapcha_open_key = payload?.recapcha_open_key;
    } else {
        resetUser(state);
    }
};

const userState = createSlice({
    name: 'userState',
    initialState: userInitialState,
    reducers: {
        logout: resetUser,
        refresh: setUser
    },
    extraReducers: (builder: ActionReducerMapBuilder<userStateType>) => {
        builder
        .addMatcher(userData.endpoints.getUserData.matchFulfilled, setUser)
        .addMatcher(userData.endpoints.getUserData.matchRejected, (state, { payload }: any) => {
            const { data } = payload;
            resetUser(state);
            state.recapcha_open_key = data?.recapcha_open_key;
        })
        .addMatcher(authApi.endpoints.logout.matchFulfilled, resetUser)
    },
});

export const { logout, refresh } = userState.actions;

export const selectUserState = (state: AppRootState) => state.userState;

export default userState.reducer;