import { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import App from "../App";

const MainPage = lazy(() => import("../pages/MainPage").then((module) => ({
    default: module.MainPage
})));

const VideoPage = lazy(() => import("../pages/VideoPage").then((module) => ({
    default: module.VideoPage
})));

const AuthPage = lazy(() => import("../pages/AuthPage").then((module) => ({ 
    default: module.AuthPage
})));

const Recover = lazy(() => import("../pages/RecoverPwdPage").then((module) => ({ 
    default: module.RecoverPwdPage
})));

const Activation = lazy(() => import("../pages/Activation").then((module) => ({ 
    default: module.Activation
})));

const MyAccount = lazy(() => import("../pages/MyAccount").then((module) => ({ 
    default: module.MyAccount
})));

const Notifications = lazy(() => import("../pages/Notifications").then((module) => ({ 
    default: module.Notifications
})));

const Subscriptions = lazy(() => import("../pages/Subscriptions").then((module) => ({ 
    default: module.Subscriptions
})));

const Feedback = lazy(() => import("../pages/Feedback").then((module) => ({ 
    default: module.Feedback
})));

const UserData = lazy(() => import("../pages/UserData").then((module) => ({ 
    default: module.UserData
})));

const VideoList = lazy(() => import("../pages/VideoList").then((module) => ({ 
    default: module.VideoList
})));

const Connect = lazy(() => import("../pages/Connect").then((module) => ({ 
    default: module.Connect
})));

const baseRoutes = [
    {
        path: 'validation/:key',
        element: <Activation type="email"/>
    },
    {
        path: 'changemail/:key',
        element: <Activation type="newEmail"/>
    },
    {
        path: '*',
        element: <Navigate to='/'/>
    },
];

const noAuthRoutes = [
    {
        path: 'login',
        element: <AuthPage/>
    },
    {
        path: 'signup',
        element: <AuthPage/>
    },
    {
        path: 'recover',
        element: <Recover/>
    },
    {
        path: 'recover/:key',
        element: <Recover/>
    },
    {
        path: 'activation/:key',
        element: <Activation/>
    },
    {
        path: '/',
        element: <Navigate to='/login'/>
    },
    {
        path: '*',
        element: <Navigate to='/login'/>
    },
];

const privateRoutes = [
    {
        path: '/',
        element: <MainPage/>,
        children: [
            {
                path: '',
                element: <VideoList/>
            }
        ]
    },
    {
        path: 'video/:id',
        element: <VideoPage/>
    },
    {
        path: 'connect',
        element: <Connect/>
    },
    {
        path: 'account',
        element: <MyAccount/>,
        children: [
            {
                path: '',
                element: <Navigate to='/account/subscriptions'/>
            },
            {
                path: 'subscriptions',
                element: <Subscriptions/>
            },
            {
                path: 'notifications',
                element: <Notifications/>
            },
            {
                path: 'userdata',
                element: <UserData/>
            },
            {
                path: 'feedback',
                element: <Feedback/>
            },
        ],
    },
];

export const getRoutesByAuth = (isAuth: boolean) => {
    const routes: Array<RouteObject> = [
        {
            path: '/',
            element: <App/>,
            children: [
                ...baseRoutes,
                ...(isAuth ? privateRoutes : noAuthRoutes)
            ]
        }
    ];

    return routes;
}