import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const baseUrl = `${process.env.REACT_APP_API_URL}/api`;

export const tags = {
    userData: 'userData',
    topVideo: 'topVideo',
    newVideo: 'newVideo',
    searchVideo: 'searchVideo',
    mainPage: 'mainPage',
    category: 'category',
    organization: 'organization',
    notifications: 'notifications'
};

export const methods = {
    post: 'POST',
    get: 'GET',
    put: 'PUT',
    delete: 'DELETE'
}

export const toFormData = (data: Object) => {
    const body = new FormData();

    for (const [ key, value ] of Object.entries(data)) {
        body.append(key, value);
    }

    return body;
}

export const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl,
        mode: 'cors',
        credentials: 'include'
    }),
    reducerPath: 'api',
    tagTypes: Object.values(tags),
    endpoints: () => ({})
});