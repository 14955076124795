import { createListenerMiddleware } from "@reduxjs/toolkit";
import { authApi } from "./endpoints/authApi";
import { userData } from "./endpoints/userData";
import { api } from "./api";

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
    matcher: authApi.endpoints.login.matchFulfilled,
    effect: async (action, listenerApi) => {    
        listenerApi.dispatch(userData.endpoints.getUserData.initiate(undefined, { forceRefetch: true }))
    }
});

listenerMiddleware.startListening({
    matcher: authApi.endpoints.logout.matchFulfilled, 
    effect: async (action, listenerApi) => {
        listenerApi.dispatch(api.util.resetApiState());
    }
});

export default listenerMiddleware.middleware;